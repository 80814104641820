<template>
  <v-app>
    <v-main>
      <router-view/>
    </v-main>
    <v-snackbar
      v-model="snackbarShow"
      location="top left"
    >
      <p v-html="snackbarText"></p>
      <template v-slot:actions>
        <v-btn
          color="red"
          variant="text"
          @click="snackbarShow = false"
        >
          X
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    snackbarShow: false,
  }),
  computed: {
    snackbarText() {
      return this.$store.state.main.snackbar.text;
    },
  },
  watch: {
    snackbarText() {
      this.snackbarShow = true;
    },
  },
  methods: {
    checkUpdates() {
      this.$store.dispatch('main/tryUpdateCheck');
      setInterval(() => {
        this.$store.dispatch('main/tryUpdateCheck').then(() => {
          // console.log(this.$store.state.main.lastUpdateCheck);
        });
      }, (1000 * 60)); // Alle 60 Sekunden
    },
  },
  created() {
    document.title = 'BERG SUN';
    this.checkUpdates();
  },
};
</script>
<style>
</style>
