import { createStore } from 'vuex';

import main from '@/store/modules/main';
import fotoUpload from '@/store/modules/fotoUpload';

export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    main,
    fotoUpload,
  },
});
