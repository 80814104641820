export default {
  namespaced: true,
  state: {
    frontendVersion: '1.2.0',
    loadingLastUpdateCheck: false,
    lastUpdateCheck: {
      version: '',
      date: '',
      dateDE: '',
    },
    inputValidationRules: {
      maxlength: (value) => value.length < 5 || 'zu lang',
      required: (value) => !!value || 'Bitte angeben!',
      counter: (value) => value.length <= 20 || 'Max 20 characters',
      email: (value) => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || 'ungültige E-Mail-Adresse!';
      },
      eGK: (value) => {
        const pattern = /^[a-zA-z0-9][0-9]{9,9}$/g;
        return (pattern.test(value) && value.length === 10) || 'ungültige eGK-Nummer!';
      },
      kurzbeschreibung: (value) => {
        const pattern = /^[a-zA-Z0-9., ]{0,40}$/g;
        return (pattern.test(value) || !value) || 'Sonderzeichen sind nicht erlaubt. Max. 40 Zeichen.';
      },
    },
    snackbar: {
      text: '',
    },
  },
  getters: {
  },
  mutations: {
    updateCheck(state, { version }) {
      const date = new Date();
      const jahr = date.getFullYear();
      const monat = String(date.getMonth() + 1).padStart(2, '0');
      const tag = String(date.getDate()).padStart(2, '0');
      const stunde = String(date.getHours()).padStart(2, '0');
      const minute = String(date.getMinutes()).padStart(2, '0');
      state.lastUpdateCheck = {
        version,
        date: `${jahr}-${monat}-${tag} ${stunde}:${minute}`,
        dateDE: `${tag}.${monat}.${jahr} ${stunde}:${minute}`,
      };
    },
    showSnackbar(state, text) {
      state.snackbar.text = '';
      setTimeout(() => {
        state.snackbar.text = text;
      }, 100);
    },
  },
  actions: {
    async tryUpdateCheck({ state, commit }) {
      state.loadingLastUpdateCheck = true;
      state.lastUpdateCheck.dateDE = '--';
      const body = new FormData();
      const signal = AbortSignal.timeout(8000);
      await fetch('https://kdz.entwicklung.bergische-krankenkasse.de/api/updateCheck.php', {
        method: 'post',
        body,
        signal,
      })
        .then((response) => response.json())
        .then((res) => {
          setTimeout(() => {
            state.loadingLastUpdateCheck = false;
            if (res.version) {
              if (
                state.lastUpdateCheck.version !== ''
                && res.version !== state.lastUpdateCheck.version
              ) {
                window.location.reload();
              } else {
                commit('updateCheck', { version: res.version });
              }
            }
          }, 1000);
        })
        .catch((error) => {
          setTimeout(() => {
            state.loadingLastUpdateCheck = false;
          }, 1000);
          console.log(error);
        });
    },
  },
  modules: {
  },
};
