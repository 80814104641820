<template>
  <div>
    <v-btn
      @click="showDialog = true"
      color="warning"
      v-if="allowSmsWarnsystem"
    >
      <v-icon start>mdi-alert</v-icon>
      SMS Meldung
    </v-btn>
    <v-dialog
      persistent
      v-model="showDialogSuccess"
      width="400px"
    >
      <v-card>
        <v-card-title>Senden erfolgreich</v-card-title>
        <v-card-text>
          Die Nachricht wurde erfolgreich übermittelt.
          <div v-if="sendStatus.statusInfo">
            <v-alert
              border="bottom"
              border-color="warning"
              elevation="2"
              class="my-2"
              v-if="sendStatus.demo === true"
            >
              TEST ist aktiviert! Es wurden keine E-Mails oder SMS wirklich gesendet!
            </v-alert>
            <div v-if="sendStatus.statusInfo.log_mail[0]">
              E-Mails gesendet: {{ sendStatus.statusInfo.log_mail[0].empfaenger.length }}
            </div>
            <div v-if="sendStatus.statusInfo.log_sms && sendStatus.statusInfo.sms_kosten">
              SMS gesendet: {{ sendStatus.statusInfo.log_sms.length }}
              (Kosten: {{ sendStatus.statusInfo.sms_kosten }})
            </div>
            <div v-if="sendStatus.statusInfo.sms_kosten">
              SMS übrig: {{ sendStatus.statusInfo.sms_uebrig }}
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            @click="showDialogSuccess = false"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      persistent
      v-model="showDialog"
      scrollable
      width="500px"
    >
      <v-card>
        <v-progress-linear
          v-if="loading"
          indeterminate
          color="primary"
        ></v-progress-linear>
        <v-card-title>SMS Meldung</v-card-title>
        <v-card-text>
          <v-form v-model="formValid">
            <v-select
              multiple
              v-model="auswahlEmpfaengergruppen"
              label="Empfängergruppen"
              :items="
                empfaengerGruppen
              "
              item-value="id"
              item-title="name"
              :diabled="loading"
            >
              <template v-slot:append-inner>
                <v-btn
                  icon="mdi-plus-box-multiple"
                  size="x-small"
                  variant="text"
                  @click.stop="
                    empfaengerGruppen.forEach((item) => {
                      if (!auswahlEmpfaengergruppen.includes(item.id)) {
                        auswahlEmpfaengergruppen.push(item.id);
                      }
                    })
                  "
                  :disabled="
                    auswahlEmpfaengergruppen.length >= empfaengerGruppen.length
                  "
                />
                <v-btn
                  icon="mdi-minus-box-multiple"
                  size="x-small"
                  variant="text"
                  @click.stop="
                    auswahlEmpfaengergruppen.splice(0, auswahlEmpfaengergruppen.length);
                  "
                  :disabled="
                    auswahlEmpfaengergruppen.length <= 0
                  "
                />
              </template>
            </v-select>
            <v-textarea
              v-model="nachricht"
              label="Meldungstext"
              prepend-icon="mdi-card-account-details"
              :rules="[
                $store.state.main.inputValidationRules.required,
              ]"
              :diabled="loading"
              :hint="`Anzahl Zeichen: ${nachricht.length}
               (Zeilenumbrüche werden zu Leerzeichen)`"
            />
            <v-checkbox
              v-model="isDemo"
              color="warning"
              label="Test (SMS und E-Mails nicht wirklich senden)"
              style="color:rgba(var(--v-theme-warning));"
            />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="showDialog = false">abbrechen</v-btn>
          <v-spacer />
          <v-btn
            :loading="loading"
            :color="isDemo ? 'warning' : 'success'"
            :disabled="
              !(
                formValid
                && auswahlEmpfaengergruppen.length > 0
              )
            "
            @click="tryGetEmpfaenger(true)"
          >
            {{ isDemo ? 'Testen' : 'Senden' }}
            {{ '('+sendenSms.length+' SMS, '+sendenMails.length+' E-Mails)' }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbarShow"
      location="top left"
    >
      <p v-html="snackbarText"></p>
      <template v-slot:actions>
        <v-btn
          color="red"
          variant="text"
          @click="snackbarShow = false"
        >
          X
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>

export default {
  name: 'SmsWarnsystem',

  data: () => ({
    allowSmsWarnsystem: true,
    snackbarShow: false,
    snackbarText: '',
    showDialog: false,
    showDialogSuccess: false,
    formValid: null,
    loading: false,
    empfaenger: [],
    empfaengerGruppen: [],
    auswahlEmpfaengergruppen: [],
    nachricht: '',
    sendenMails: [],
    sendenSms: [],
    isDemo: true,
    sendStatus: '',
  }),
  computed: {
  },
  watch: {
    showDialog(neu) {
      if (neu === true) {
        this.tryGetEmpfaenger(false);
      }
    },
    auswahlEmpfaengergruppen(neuGruppen) {
      const sms = [];
      const mails = [];
      neuGruppen.forEach((idGruppe) => {
        this.empfaenger.filter((o) => o.id_gruppe === idGruppe).forEach((e) => {
          let { handynummer } = e;
          handynummer = handynummer.split('/').join('');
          handynummer = handynummer.split('-').join('');
          handynummer = handynummer.split('+49').join('0049');
          if (!sms.includes(handynummer) && handynummer !== '') {
            sms.push(handynummer);
          }
          if (!mails.includes(e.email) && e.email !== '') {
            mails.push(e.email);
          }
          if (!mails.includes(e.emailPrivat) && e.emailPrivat !== '') {
            mails.push(e.emailPrivat);
          }
        });
      });
      this.sendenMails = mails;
      this.sendenSms = sms;
    },
  },
  methods: {
    async tryGetEmpfaenger(senden) {
      this.loading = true;
      const body = new FormData();
      const localSettings = JSON.parse(localStorage.getItem('main_settings'));
      body.append('apiKey', localSettings.idCode);
      await fetch('https://sms.entwicklung.bergische-krankenkasse.de/api/?getEmpfaenger', {
        method: 'POST',
        body,
      })
        .then((response) => response.json())
        .then((res) => {
          // console.log(res);
          if (res.status === 'error') {
            console.error(res);
            this.loading = false;
            this.snackbarText = '<b>Fehler!</b> Authorisierung fehlgeschlagen';
            this.snackbarShow = true;
            this.showDialog = false;
            this.allowSmsWarnsystem = false;
            localSettings.useSmsWarnsystem = false;
            localStorage.setItem('main_settings', JSON.stringify(localSettings));
          } else {
            this.empfaenger = res.empfaenger;
            const empfaengerGruppen = [];
            res.empfaenger.forEach((e) => {
              if (empfaengerGruppen.filter((o) => o.id === e.id_gruppe).length <= 0) {
                empfaengerGruppen.push({
                  id: e.id_gruppe,
                  name: e.gruppe,
                });
              }
            });
            this.empfaengerGruppen = empfaengerGruppen;
            if (this.auswahlEmpfaengergruppen.length <= 0) {
              const auswahlEmpfaengergruppen = [];
              this.empfaengerGruppen.forEach((g) => {
                auswahlEmpfaengergruppen.push(g.id);
              });
              this.auswahlEmpfaengergruppen = auswahlEmpfaengergruppen;
            }
            if (!senden) {
              this.loading = false;
            } else {
              this.trySend();
            }
          }
        })
        .catch((error) => console.error(error));
    },
    async trySend() {
      this.loading = true;
      const body = new FormData();
      const localSettings = JSON.parse(localStorage.getItem('main_settings'));
      body.append('apiKey', localSettings.idCode);
      body.append('mails', JSON.stringify(this.sendenMails));
      body.append('sms', JSON.stringify(this.sendenSms));
      body.append('demo', this.isDemo);
      body.append('msg', this.nachricht.split('\n').join(' '));
      await fetch('https://sms.entwicklung.bergische-krankenkasse.de/api/?senden', {
        method: 'POST',
        body,
      })
        .then((response) => response.json())
        .then((res) => {
          // console.log(res);
          this.sendStatus = res;
          this.showDialogSuccess = true;
          this.loading = false;
        });
    },
  },
  created() {
  },
};
</script>
