export default {
  namespaced: true,
  state: {
    kategorien: [
      {
        id: 1, kuerzel: 'AUs', name: 'AU', maxFotos: 1, standard: true, textfelder: ['EGK'],
      },
      {
        id: 2, kuerzel: 'eGK-Fotos', name: 'eGK Foto', maxFotos: 1, standard: true, textfelder: ['EGK'],
      },
      {
        id: 3, kuerzel: 'eGK-Fotos/Interessenten', name: 'Lichtbild Interessenten', maxFotos: 1, standard: false, textfelder: ['freitext'],
      },
      {
        id: 4, kuerzel: 'eGK-Fotos', name: 'Lichtbild Mitglieder + Fami 21c', maxFotos: 1, standard: false, textfelder: ['EGK'],
      },
      {
        id: 5, kuerzel: 'Rechnungen', name: 'Rechnung', maxFotos: 0, standard: true, textfelder: ['EGK'],
      },
      {
        id: 6, kuerzel: 'Anträge', name: 'Antrag', maxFotos: 0, standard: true, textfelder: ['EGK'],
      },
    ],
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  },
};
