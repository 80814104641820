<template>
  <div>
    <v-btn
      @click="showDialog = true"
      color="primary"
      :disabled="this.nutzbareKategorien.length <= 0"
      v-if="settings.useFotoUpload"
    >
      <v-icon start>mdi-camera</v-icon>
      Foto hochladen
    </v-btn>
    <v-dialog
      persistent
      v-model="showDialogSuccess"
      width="400px"
    >
      <v-card>
        <v-card-title>Hochladen erfolgreich</v-card-title>
        <v-card-text>
          Die gesendeten Daten wurden erfolgreich übermittelt.
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            @click="showDialogSuccess = false"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      persistent
      v-model="showDialog"
      scrollable
      width="500px"
    >
      <v-card>
        <v-card-title>Foto hochladen</v-card-title>
        <v-card-text>
          <v-form v-model="formValid">
            <v-text-field
              v-model="formPin"
              label="Personalnummer"
              placeholder=""
              prepend-icon="mdi-account-check"
              :rules="[
              ]"
            />
            <v-select
              v-model="formKuerzelKategorie"
              label="Kategorie"
              :items="
                $store.state.fotoUpload.kategorien
                  .filter((o) => settings.nutzbareKategorien.includes(o.id))
              "
              item-value="kuerzel"
              item-title="name"
            />
            <v-text-field
              v-if="
                $store.state.fotoUpload.kategorien.filter((o) => {
                  if (
                    o.kuerzel === formKuerzelKategorie
                    && o.textfelder.includes('EGK')
                  ) {
                    return o;
                  }
                  return null;
                }).length > 0
              "
              v-model="formEgknumber"
              label="eGK Nummer"
              placeholder="A123456789"
              prepend-icon="mdi-card-account-details"
              :rules="[
                $store.state.main.inputValidationRules.required,
                $store.state.main.inputValidationRules.eGK,
              ]"
            />
            <v-text-field
              v-if="
                $store.state.fotoUpload.kategorien.filter((o) => {
                  if (
                    o.kuerzel === formKuerzelKategorie
                    && o.textfelder.includes('freitext')
                  ) {
                    return o;
                  }
                  return null;
                }).length > 0
              "
              v-model="formKurzbeschreibung"
              label="Kurzbeschreibung"
              placeholder="Bsp.: Mustermann, Max Antrag Krankengeld"
              prepend-icon="mdi-text-short"
              :rules="[
                $store.state.main.inputValidationRules.required,
                $store.state.main.inputValidationRules.kurzbeschreibung,
              ]"
            />
            <v-alert
              class="mb-6"
              type="warning"
              style="font-size:80%;"
            >
              Beim Fotografieren bitte darauf achten, dass der Auslöser am rechten
              Bildschirmrand erscheint.
              Ist dieser an einem anderen Bildschirmrand, drehen Sie das Gerät so,
              dass er rechts angezeigt wird.
            </v-alert>
            <v-row
              v-for="(img, idx) in formImages" :key="img.id"
            >
              <v-col>
                <v-file-input
                  :multiple="
                    $store.state.fotoUpload.kategorien
                      .filter((o) => o.kuerzel === formKuerzelKategorie)[0].maxFotos !== 1
                  "
                  v-model="img.file"
                  :label="`Foto #${idx + 1} wählen`"
                  prepend-icon="mdi-camera"
                  accept="image/jpeg, image/png"
                  @change="selectedImages(idx, img.file)"
                  :ref="`fileinput${idx + 1}`"
                />
              </v-col>
              <v-col cols="3" align="center" class="pt-6 d-flex justify-space-around"
                v-if="
                  $store.state.fotoUpload.kategorien
                    .filter((o) => o.kuerzel === formKuerzelKategorie)[0].maxFotos !== 1
                "
              >
                <v-btn
                  v-if="formImages.length > 1"
                  size="x-small" icon="mdi-minus-box" @click="delImage(img);" />
                <v-btn size="x-small" icon="mdi-plus-box" @click="addImage(img);" />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="showDialog = false">abbrechen</v-btn>
          <!-- <v-btn @click="resetUploadForm();">Zurücksetzen</v-btn> -->
          <v-spacer />
          <v-btn
            :loading="loading"
            :disabled="
              !(
                (
                  this.formValid
                  && this.formImages.filter((o) => o.file.length > 0).length > 0
                )
                || (this.formPin.substring(0, 1) === '+')
                || (this.formPin.substring(0, 1) === '-')
              )
            "
            @click="upload();"
            color="primary"
          >
            Hochladen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbarShow"
      location="top left"
    >
      <p v-html="snackbarText"></p>
      <template v-slot:actions>
        <v-btn
          color="red"
          variant="text"
          @click="snackbarShow = false"
        >
          X
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
/* eslint-disable */

export default {
  name: 'FotoUpload',
  props: {
    settings: {
      type: Object,
      default() { return {}; },
    },
  },
  data: () => ({
    snackbarShow: false,
    snackbarText: '',
    showDialog: false,
    showDialogSuccess: false,
    loading: false,
    formValid: false,
    formImages: [],
    formPin: '',
    formEgknumber: '',
    formKurzbeschreibung: '',
    formKuerzelKategorie: null,
  }),
  computed: {
    nutzbareKategorien() {
      const res = this.$store.state.fotoUpload.kategorien
        .filter((o) => this.settings.nutzbareKategorien.includes(o.id));
      return res;
    },
    /* useFotoUpload() {
      const localSettings = JSON.parse(localStorage.getItem('main_settings'));
      if (!Object.keys(localSettings).includes('useFotoUpload')) {
        localSettings.useFotoUpload = true;
        localStorage.setItem('main_settings', JSON.parse(localSettings));
      }
      if (localSettings.useFotoUpload) {
        return true;
      }
      return false;
    }, */
  },
  watch: {
    showDialog(neu) {
      if (this.nutzbareKategorien.length > 0 && !this.formKuerzelKategorie) {
        this.formKuerzelKategorie = this.nutzbareKategorien[0].kuerzel;
      }
      if (neu === false) {
        // this.formPin = '';
        // resetUploadForm
      } else if (this.formImages.length <= 0) {
        this.addImage();
      }
      if (neu === true && this.formEgknumber !== '') {
        const egk = this.formEgknumber;
        this.formEgknumber = '';
        setTimeout(() => {
          this.formEgknumber = egk;
        }, 100);
      }
    },
    formEgknumber(neu) {
      this.formEgknumber = neu.toUpperCase();
    },
    formKuerzelKategorie(neu) {
      const { maxFotos } = this.$store.state.fotoUpload.kategorien
        .filter((o) => o.kuerzel === neu)[0];
      if (maxFotos > 0) {
        while (maxFotos < this.formImages.length) {
          this.formImages.pop();
        }
      }
    },
  },
  methods: {
    resetUploadForm() {
      this.formEgknumber = '';
      this.formKurzbeschreibung = '';
      this.formImages = [];
    },
    selectedImages(idx, files) {
      if (files.length > 1) {
        files.forEach((f, i) => {
          this.formImages.splice((parseInt(idx, 10) + i + 1), 0, {
            id: this.formImages.length,
            file: [f],
          });
        });
        this.delImage(this.formImages[idx]);
      }
    },
    addImage(img) {
      const idx = this.formImages.indexOf(img) + 1;
      this.formImages.splice(idx, 0, {
        id: this.formImages.length,
        file: [],
      });
    },
    delImage(img) {
      this.formImages.splice(this.formImages.indexOf(img), 1);
      // IDs neu sortieren:
      let i = 0;
      this.formImages.forEach((j, idx) => {
        this.formImages[idx].id = i;
        i += 1;
      });
    },
    async upload() {
      const uploadFiles = {};
      this.formImages.forEach((img) => {
        if (img.file[0]) {
          uploadFiles[`image${img.id}`] = img.file[0];
        }
      });
      const doUploads = [];
      if (
        ['Anträge', 'Rechnungen'].includes(this.formKuerzelKategorie)
        && Object.keys(uploadFiles).length >= 2
      ) {
        const now = new Date();
        const year = now.getFullYear();
        const month = (now.getMonth() + 1).toString().padStart(2, '0');
        const day = now.getDate().toString().padStart(2, '0');
        const hours = now.getHours().toString().padStart(2, '0');
        const minutes = now.getMinutes().toString().padStart(2, '0');
        const seconds = now.getSeconds().toString().padStart(2, '0');
        const dateTimeString = `${year}-${month}-${day}-${hours}-${minutes}-${seconds}`;
        let i = 1;
        Object.keys(uploadFiles).forEach((k) => {
          const data = {
            image0: uploadFiles[k],
            teilupload: JSON.stringify({
              id: dateTimeString,
              idChunk: i,
              anzahlChunks: Object.keys(uploadFiles).length,
            }),
          };
          doUploads.push({
            data,
          });
          i += 1;
        });
      } else {
        doUploads.push({
          data: uploadFiles,
        });
      }
      for (const upload of doUploads) {
        const body = new FormData();
        body.append('egkNumber', this.formEgknumber);
        body.append('kurzbeschreibung', this.formKurzbeschreibung);
        body.append('idKategorie', this.formKuerzelKategorie);
        body.append('idCode', this.settings.idCode);
        body.append('pin', this.formPin);
        Object.keys(upload.data).forEach((k) => {
          if (k === 'uploadFiles') {
            Object.keys(upload.data[k]).forEach((k2) => {
              body.append(k, upload.data[k][k2]);
            });
          } else {
            body.append(k, upload.data[k]);
          }
        });
        let teilupload = false;
        if (upload.data.teilupload) {
          teilupload = JSON.parse(upload.data.teilupload);
        }
        // --
        this.loading = true;
        await fetch('https://kdz.entwicklung.bergische-krankenkasse.de/api/', {
          method: 'POST',
          body,
        })
          .then((response) => response.json())
          .then(async (res) => {
            if (
              !teilupload
              || teilupload?.idChunk >= teilupload?.anzahlChunks
            ) {
              this.loading = false;
              if (res.status !== 'success') {
                if (res.status === 'adminPinsAdded') {
                  this.snackbarText = '<b>Erfolg!</b> Angegebene Personalnummern hinzugefügt.';
                }
                if (res.status === 'adminPinsDeleted') {
                  this.snackbarText = '<b>Erfolg!</b> Angegebene Personalnummern gelöscht.';
                }
                if (res.status === 'adminPinsShow') {
                  this.snackbarText = `<b>Alle Personalnummern:</b> ${res.statusInfo}`;
                }
                if (res.status === 'errorId') {
                  this.snackbarText = '<b>Fehler!</b> Ungültige ID.';
                }
                if (res.status === 'errorPin') {
                  this.snackbarText = '<b>Fehler!</b> Ungültige Personalnummer.';
                }
                if (res.status === 'errorNoFiles') {
                  this.snackbarText = '<b>Fehler!</b> Keine Datei ausgewählt.';
                }
                if (res.status === 'errorFileExtensions') {
                  this.snackbarText = `<b>Fehler!</b> ${res.statusInfo}`;
                }
                this.snackbarShow = true;
              } else {
                // this.formEgknumber = '';
                this.formKurzbeschreibung = '';
                this.formImages = [];
                this.addImage();
                this.showDialog = false;
                this.showDialogSuccess = true;
              }
            }
          })
          .catch((error) => {
            this.loading = false;
            this.snackbarText = '<b>Fehler!</b> Bitte die Netzwerkverbindung prüfen.';
            this.snackbarShow = true;
            console.log(error);
          });
        // -- 
      }
    },
  },
  created() {
    if (this.formImages.length <= 0) {
      this.addImage();
    }
  },
};
</script>
