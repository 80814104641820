<template>
  <v-container fluid
    class="d-flex align-center justify-center"
    style="
      min-height:100%;
      background-image:url('/img/background.png');
      background-size:cover;
      background-position:50% 50%;
    "
  >
    <v-btn
      size="x-small"
      style="position:absolute;top:10px;right:10px;"
      text icon="mdi-cogs"
      @click="dialogSettings = true;"
    />
    <v-dialog
      v-model="dialogSettings"
      width="500px"
    >
      <v-card>
        <v-card-title>Einstellungen</v-card-title>
        <v-card-text>
          <v-text-field
            v-model="settings.idCode"
            label="ID Code"
          />
          <v-container>
            <p>Funktionen:</p>
            <v-checkbox
              label="Foto-Upload"
              v-model="settings.useFotoUpload"
            />
            <v-select
              v-if="settings.useFotoUpload"
              multiple
              label="Nutzbare Kategorien"
              :items="$store.state.fotoUpload.kategorien"
              item-value="id"
              item-title="name"
              v-model="settings.nutzbareKategorien"
            >
              <template v-slot:append-inner>
                <v-btn
                  icon="mdi-plus-box-multiple"
                  size="x-small"
                  variant="text"
                  @click.stop="
                    $store.state.fotoUpload.kategorien.forEach((kategorie) => {
                      if (!settings.nutzbareKategorien.includes(kategorie.id)) {
                        settings.nutzbareKategorien.push(kategorie.id);
                      }
                    })
                  "
                  :disabled="
                    settings.nutzbareKategorien.length >= $store.state.fotoUpload.kategorien.length
                  "
                />
                <v-btn
                  icon="mdi-minus-box-multiple"
                  size="x-small"
                  variant="text"
                  @click.stop="
                    settings.nutzbareKategorien.splice(0, settings.nutzbareKategorien.length);
                  "
                  :disabled="
                    settings.nutzbareKategorien.length <= 0
                  "
                />
              </template>
            </v-select>
            <v-checkbox
              label="SMS Warnsystem"
              @change="checkAllowSmsWarnsystem();"
              v-model="settings.useSmsWarnsystem"
            />
          </v-container>
          <hr />
          <p class="my-4">
            Letzte Überprüfung auf Updates: {{ this.$store.state.main.lastUpdateCheck.dateDE }}
          </p>
          <v-row>
            <v-col cols="3">Frontend:</v-col>
            <v-col>
              <b>v{{ this.$store.state.main.frontendVersion }}</b>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3">Backend:</v-col>
            <v-col>
              <b>v{{ this.$store.state.main.lastUpdateCheck.version }}</b>
            </v-col>
          </v-row>
          <div align="center" class="mt-3">
            <v-btn
              @click="this.$store.dispatch('main/tryUpdateCheck')"
              size="x-small"
              :loading="this.$store.state.main.loadingLastUpdateCheck"
            >
              Nach Updates suchen
            </v-btn>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary" text
            @click="dialogSettings = false"
          >ok</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <FotoUpload class="ma-1" :settings="settings" />
    <SmsWarnsystem class="ma-1" v-if="settings.useSmsWarnsystem" />
  </v-container>
</template>

<script>
import { defineComponent } from 'vue';

import FotoUpload from '@/components/FotoUpload.vue';
import SmsWarnsystem from '@/components/SmsWarnsystem.vue';

export default defineComponent({
  name: 'HomeView',
  components: {
    FotoUpload,
    SmsWarnsystem,
  },
  data: () => ({
    dialogSettings: false,
    settings: {
      idCode: '',
      nutzbareKategorien: [],
      useFotoUpload: true,
      useSmsWarnsystem: false,
    },
  }),
  watch: {
    settings: {
      handler(neu) {
        localStorage.setItem('main_settings', JSON.stringify(neu));
      },
      deep: true,
    },
  },
  methods: {
    async checkAllowSmsWarnsystem() {
      if (this.settings.useSmsWarnsystem) {
        const body = new FormData();
        body.append('apiKey', this.settings.idCode);
        await fetch('https://sms.entwicklung.bergische-krankenkasse.de/api/?auth', {
          method: 'POST',
          body,
        })
          .then((response) => response.json())
          .then((res) => {
            console.log(res);
            if (res.status !== 'success') {
              this.settings.useSmsWarnsystem = false;
              this.$store.commit('main/showSnackbar', '<b>Fehler!</b> Authorisierung fehlgeschlagen');
            }
          })
          .catch((error) => {
            console.log(error);
            this.settings.useSmsWarnsystem = false;
          });
      }
    },
  },
  created() {
    if (localStorage.getItem('main_settings')) {
      const localSettings = JSON.parse(localStorage.getItem('main_settings'));
      if (!localSettings.nutzbareKategorien) {
        // Fallback:  Wenn lokal noch keine Kategorien eingestellt wurden,
        //            aktiviere die Standardkategorien
        localSettings.nutzbareKategorien = [];
        this.$store.state.fotoUpload.kategorien.forEach((k) => {
          if (k.standard) {
            localSettings.nutzbareKategorien.push(k.id);
          }
        });
      }
      if (!Object.keys(localSettings).includes('useFotoUpload')) {
        // Fallback:  Wenn lokal noch nicht eingestellt wurde, dass FotoUpload
        //            verwendet werden soll, aktiviere standardmäßig
        localSettings.useFotoUpload = true;
      }
      this.settings = localSettings;
    }
  },
});
</script>
<style>
</style>
